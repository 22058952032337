import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import javafortrading from "../assets/svg/projects/javafortrading.png"
import reactonrails from "../assets/png/reactonrails.png"
import derivativeanalytics from "../assets/svg/projects/derivativeanalytics.png"
import socketio from "../assets/png/socketio.png"
import reactnative from "../assets/png/reactnative.png"
import angular from "../assets/png/angular.png"
import pythontrading from "../assets/png/pythontrading.png"

import reactonrailsvideo from "../assets/svg/projects/reactonrailsvideo.mp4"
import derivativeanalyticsvideo from "../assets/svg/projects/derivativeanalyticsvideo.mp4"
import socketiovideo from "../assets/svg/projects/socketiovideo.mp4"
import pythonfortradingvideo from "../assets/svg/projects/pythonfortradingvideo.mp4"
import reactnativevideo from "../assets/svg/projects/reactnativevideo.mp4"
import angular2video from "../assets/svg/projects/angular2video.mp4"


export const projectsData = [

    {
        id: 2,
        projectName: 'React on Rails',
        projectDesc: 'This talk was given at the San Diego JavaScript Users Group in 2017.',
        tags: ['React', 'Ruby', 'Rails'],
        code: '',
        demo: reactonrailsvideo,
        image: reactonrails
    },
    {
        id: 3,
        projectName: 'Python for Derivative Analytics',
        projectDesc: 'I gave this talk at the San Diego Python Users Group in 2017.',
        tags: ['Python', 'numpy', 'pandas'],
        code: '',
        demo: derivativeanalyticsvideo,
        image: derivativeanalytics
    },
    {
        id: 4,
        projectName: 'Real-Time Apps with React & Socket.io',
        projectDesc: 'I gave this talk at the San Diego React Developers Meetup in 2017.',
        tags: ['React', 'socket.io', 'web sockets'],
        code: '',
        demo: socketiovideo,
        image: socketio
    },
    {
        id: 5,
        projectName: 'Python for Options Trading',
        projectDesc: 'I gave this talk in 2016 at the San Diego Python Users Group.',
        tags: ['Python', 'SciPy'],
        code: '',
        demo: pythonfortradingvideo,
        image: pythontrading
    },
    {
        id: 6,
        projectName: 'Mobile Development with React Native',
        projectDesc: 'I gave this talk at the San Diego JavaScript Users Group in 2016.',
        tags: ['React Native'],
        code: '',
        demo: reactnativevideo,
        image: reactnative
    },
    {
        id: 7,
        projectName: 'AngularJS to Angular 2',
        projectDesc: 'I gave this talk at the San Diego JavaScript Users Group in 2016.',
        tags: ['Angular', 'JavaScript', 'TypeScript'],
        code: '',
        demo: angular2video,
        image: angular
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/