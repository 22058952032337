/* eslint-disable */
import {FaDatabase, FaLaptopCode, FaChartLine} from "react-icons/fa";
import {GiArtificialIntelligence, GiTeacher} from "react-icons/gi";
import {VscSymbolArray} from "react-icons/vsc";
import {CgWebsite} from "react-icons/cg";
import { FaInternetExplorer,  FaChalkboardTeacher, FaCameraRetro, FaPinterest, FaVideo, FaTabletAlt, FaRegNewspaper } from "react-icons/fa";

 

export const servicesData = [
    // {
    //     id: 1,
    //     title: 'Marketing',
    //     icon: <BiShoppingBag/>
    // },
    {
        id: 2,
        title: 'Front End Web Development',
        icon: <CgWebsite />
    },
    {
        id: 3,
        title: 'Back End Web Development',
        icon: <FaLaptopCode />
    },
    // {
    //     id: 4,
    //     title: 'Internet Research',
    //     icon: <FaInternetExplorer />
    // },
    // {
    //     id: 5,
    //     title: 'Tutoring',
    //     icon: <FaChalkboardTeacher />
    // },

    {
        id: 6,
        title: 'Relational & NoSQL Databases',
        icon: <FaDatabase />
    },
    {
        id: 7,
        title: 'AI & Machine Learning',
        icon: <GiArtificialIntelligence />
    },
    // {
    //     id: 8,
    //     title: 'Pinterest Virtual Assistant',
    //     icon: <FaPinterest />
    // }, 
    {
        id: 9,
        title: 'Data Structures & Algorithms',
        icon: <VscSymbolArray />
    },
    // {
    //     id: 10,
    //     title: 'Data Entry', 
    //     icon: <BsClipboardData />
    // },
    {
        id: 11,
        title: 'Data Science',
        icon: <FaChartLine />
    },
    // {
    //     id: 12,
    //     title: 'Audio Transcription',
    //     icon: <AiFillAudio />
    // },
    {
        id: 13,
        title: 'Technical Training',
        icon: <GiTeacher />
    },

]

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.
