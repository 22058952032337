import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import 'react-app-protect/dist/index.css';
import Protect from 'react-app-protect';
import { ThemeContext } from './contexts/ThemeContext';
import { Main, BlogPage, ProjectPage } from './pages'
import { BackToTop } from './components'
import ScrollToTop from './utils/ScrollToTop'

import './App.css'

function App() {

  const { theme } = useContext(ThemeContext);

  console.log("%cDEVELOPER PORTFOLIO", `color:${theme.primary}; font-size:50px`);
  console.log("%chttps://github.com/hhhrrrttt222111/developer-portfolio", `color:${theme.tertiary}; font-size:20px`);
  // console.log = console.warn = console.error = () => {};

  return (
      <Protect
          sha512='ccc82e1e44c4b2093b411aee8d3da346f1e1a08c7fc10c9ee00674a43907351e4fa416be204bb9d174ca4e055f9b0f82cb5ddd00084067ca30d7740990c82017'
          blur={true}
          boxTitle={'Algorithmic Programmer only shares the content of her website with people she knows. If Algorithmic Programmer gave you a password, please enter it below. Otherwise, please contact her directly for a password.'}
          styles={{
              input: { fontFamily: 'Poppins', height: '50px', boxSizing: 'border-box', width: '100%', borderRadius: '50px', outline: 'none', marginBottom: '2rem', padding: '0.7rem 0.7rem', borderColor: "#ff4f93"},
              header: { color: 'black', fontFamily: 'Poppins', backgroundColor: '#ff4f93', marginBottom: '2.5rem'},
              button: {fontFamily: 'Poppins', fontSize: '16px', backgroundColor: '#ff4f93', border: 'none', fontWeight: '500', outline: 'none', width: '140px', height: '50px', borderRadius: '50px', alignItems: 'center'}
          }}
      >
          <div className="app">

      <Router>
        <ScrollToTop/>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/blog" exact component={BlogPage} />
          <Route path="/projects" exact component={ProjectPage} />

          <Redirect to="/" />
        </Switch>
      </Router>
      <BackToTop />
    </div>
</Protect>
  );
}

export default App;
