export const achievementData = {
    bio : "Computer science is an industry that is constantly evolving. To remain relevant and competitve, I am frequently training in the latest technologies.",
    achievements : [
        {
            id : 1,
            title : 'Front End Web Development Nanodegree',
            details : 'This intensive 3-month nanodegree program teaches a comprehensive front-end web development curriculum which includes HTML, CSS, website components, JavaScript, and the DOM.',
            date : '6/27/2024 (In Progress)',
            field : 'Udacity',
            image : 'udacitylogo.jpg'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
