export const blogData = [
    {
        id: 1,
        title: 'KUBERNETES FOR CONTAINERIZED APPLICATIONS',
        description: 'A literature review on how Kubernetes enhances containerized applications.',
        date: 'March 24, 2024',
        image: 'kubernetes.png',
        url: 'Kubernetes.pdf'
    },
    {
        id: 2,
        title: 'MULTIVARIATE DATA VISUALIZATION METHODS',
        description: 'An analysis of advanced techniques for multivariate data visualization.',
        date: 'January 13, 2024',
        image: 'multivariate.png',
        url: 'Multivariatepaper.pdf'
    },
    {
        id: 3,
        title: 'NATURAL LANGUAGE PROCESSING',
        description: 'An analysis of the most effective recent advancements in natural language processing.',
        date: 'December 25, 2023',
        image: 'nlp.jpg',
        url: 'NLPpaper.pdf'
    },
    {
        id: 4,
        title: 'NOSQL DATABASES',
        description: 'An analysis of why NoSQL databases are overtaking relational databases.',
        date: 'November 9, 2023',
        image: 'nosql.png',
        url: 'NoSQLpaper.pdf'
    },
    {
        id: 5,
        title: 'R PROGRAMMING LANGUAGE',
        description: 'An argument based on functionality thar R is a versatile programming language.',
        date: 'August 20, 2023',
        image: 'r.png',
        url: 'Rpaper.pdf'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/