export const educationData = [
    {
        id: 1,
        institution: 'Northern Arizona University',
        course: 'Master of Science, Computer Information Technology',
        startYear: '2023',
        endYear: '2025'
    },
    {
        id: 2,
        institution: 'Northern Arizona University',
        course: 'Bachelor of Science, Liberal Arts',
        startYear: '2021',
        endYear: '2023'
    },
]
