/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [



    {
        id: 4,
        name: 'Andrew Paul Acosta, Ph.D.',
        title: 'Data Scientist at Milesius Capital Resources',
        text: "[Algorithmic Programmer] accomplished an amazing amount of success in JavaScript and related technologies. Her work ethic was extraordinary and I would hire her for future projects.",
        image: boy1
    },
    {
        id: 2,
        name: 'Jeanne Conner',
        title: 'SQL Developer/Software Engineer',
        text: "[Algorithmic Programmer]'s tenacity and determination to do the work and get the job done is noteworthy. I am very impressed with her skills in full-stack web development and I would love the chance to work with her again!",
        image: girl2
    }
]

