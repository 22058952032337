export const experienceData = [
    {
        id: 1,
        company: 'Algorithmic Programmer',
        jobtitle: 'CEO',
        startYear: '2017',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Milesius Capital Resources',
        jobtitle: 'Software Engineer',
        startYear: '2016',
        endYear: '2017'
    },
    {
        id: 3,
        company: 'AudaExplore, A Solera Company',
        jobtitle: 'Software Engineer',
        startYear: '2015',
        endYear: '2016'
    },
]
